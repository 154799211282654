import React from 'react';
import AddOnCard from '../../components/AddOnCard/AddOnCard';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import IconAddOn from '../../components/IconAddOn/IconAddOn';

const SectionAddOns = (props) => {
    const { addOns, intl } = props;
    if (!addOns) {
        return null;
    }

    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <IconAddOn className={css.firstIcon} />
                <FormattedMessage id="ListingPage.addOnsTitle" />
            </h2>

            <div>
                {React.Children.toArray(
                    addOns.map((_add, index) => (
                        <AddOnCard
                            secondaryColor
                            intl={intl}
                            addOn={_add}
                            showDivider={index + 1 !== addOns.length}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default SectionAddOns;
