import React from 'react';
import IconLocation from '../../components/IconLocation/IconLocation';
import IconRuler from '../../components/IconRuler/IconRuler';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import IconMaxCap from '../../components/IconMaxCap/IconMaxCap';
import IconStar from '../../components/IconStar/IconStar';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = (props) => {
    const {
        description,
        address,
        totalCapacity,
        squaremeter,
        noOfReviews,
        averageRating,
    } = props;

    return description ? (
        <div className={css.sectionDescription}>
            <div className={css.sectionDescriptionInfoContainer}>
                {address && (
                    <div className={css.sectionDescriptionInfo}>
                        <IconLocation  className={css.icon} />
                        {address}
                    </div>
                )}
                {totalCapacity && (
                    <div className={css.sectionDescriptionInfo}>
                        <IconMaxCap className={css.filterBrightness} />
                        {totalCapacity}{' '}
                        <FormattedMessage id="SectionDescription.people" />
                    </div>
                )}
                {squaremeter && (
                    <div className={css.sectionDescriptionInfo}>
                        <IconRuler className={css.icon} />
                        {squaremeter}
                        <FormattedMessage id="SectionDescription.qm" />
                    </div>
                )}
                <div className={css.sectionDescriptionInfo}>
                    <div className={css.averageRating}>
                        <IconStar className={css.starIcon} />
                        {!isNaN(averageRating) && averageRating !== 0
                            ? averageRating
                            : null}
                    </div>
                    <div className={css.averageRating}>({noOfReviews} </div>
                    <FormattedMessage id="SectionDescription.reviews" />)
                </div>
            </div>

            <p className={css.description}>
                {richText(description, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                    longWordClass: css.longWord,
                })}
            </p>
        </div>
    ) : null;
};

export default SectionDescriptionMaybe;
