import React from 'react';

import css from './ListingPage.module.css';

const SectionHeading = (props) => {
    const { richTitle, clusters = [] } = props;
    return (
        <div className={css.sectionHeading}>
            <div className={css.heading}>
                {
                    <p className={css.activities}>
                        {React.Children.toArray(
                            clusters.map((cluster, index) => (
                                <span className={css.activity}>
                                    {cluster?.title}
                                    {clusters.length !== index + 1 ? (
                                        <span className={css.activityDivider}>
                                            •
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </span>
                            ))
                        )}
                    </p>
                }
                <h1 className={css.title}>{richTitle}</h1>
            </div>
        </div>
    );
};

export default SectionHeading;
