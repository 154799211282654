import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = (props) => {
    const {
        title,
        listing,
        isOwnListing,
        editParams,
        handleViewPhotosClick,
        imageCarouselOpen,
        onImageCarouselClose,
        onManageDisableScrolling,
    } = props;

    const hasImages = listing.images && listing.images.length > 0;
    const firstImage = hasImages ? listing.images[0] : null;

    // Action bar is wrapped with a div that prevents the click events
    // to the parent that would otherwise open the image carousel
    const actionBar = listing.id ? (
        <div onClick={(e) => e.stopPropagation()}>
            <ActionBarMaybe
                isOwnListing={isOwnListing}
                listing={listing}
                editParams={editParams}
            />
        </div>
    ) : null;

    const viewPhotosButton = hasImages ? (
        <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
            <FormattedMessage
                id="ListingPage.viewImagesButton"
                values={{ count: listing.images.length }}
            />
        </button>
    ) : null;

    //const getimgcrop = hasImages(listing, 'landscape-crop');
    // const getimagcrop = listing.images.slice(1, 4);
    // const getimg = getimagcrop.attributes.variants.facebook;
    

    return (
        <div className={css.sectionImages}>
            <div className={css.threeToTwoWrapper}>
                <div
                    className={css.aspectWrapper}
                    onClick={handleViewPhotosClick}
                >
                    {actionBar}
                    <ResponsiveImage
                        rootClassName={css.rootForImage}
                        alt={title}
                        image={firstImage}
                        variants={[
                            'landscape-crop',
                            'landscape-crop2x',
                            'landscape-crop4x',
                            'landscape-crop6x',
                        ]}
                    />
                    {viewPhotosButton}
                </div>
            </div>
            {/* <div className={css.rightImgGellery}>
                {getimg.map((index) => (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                        src={index.url}
                        className={css.ImgGelleryThumb}
                        // onClick={viewPhotosButton}
                    />
                ))}
            </div> */}
            <Modal
                id="ListingPage.imageCarousel"
                scrollLayerClassName={css.carouselModalScrollLayer}
                containerClassName={css.carouselModalContainer}
                lightCloseButton
                isOpen={imageCarouselOpen}
                onClose={onImageCarouselClose}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
            >
                <ImageCarousel images={listing.images} />
            </Modal>
        </div>
    );
};

export default SectionImages;
