import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LocationIcon2 from '../../assets/icons/location2-icon.svg';

const IconLocation2 = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={LocationIcon2} alt="map pin icon" />;
};

const { string } = PropTypes;

IconLocation2.defaultProps = {
    className: null,
    rootClassName: null,
};

IconLocation2.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconLocation2;
