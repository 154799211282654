import React from 'react';
import { AvatarLarge, AvatarMedium } from '../../components';

import css from './ListingPage.module.css';

const SectionAvatar = (props) => {
    const { user } = props;
    return (
        <div className={css.sectionAvatar}>
            <AvatarLarge
                user={user}
                className={css.avatarDesktop}
                initialsClassName={css.initialsDesktop}
            />

            <AvatarMedium user={user} className={css.avatarMobile} />
        </div>
    );
};

export default SectionAvatar;
