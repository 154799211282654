import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RulerIcon from '../../assets/icons/ruler-icon.svg';

const IconRuler = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={RulerIcon} alt="ruler Icon" />;
};

const { string } = PropTypes;

IconRuler.defaultProps = {
    className: null,
    rootClassName: null,
};

IconRuler.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconRuler;
