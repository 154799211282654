import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StarIcon from '../../assets/icons/star-icon.svg';

const IconStar = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={StarIcon} alt="Star icon" />;
};

const { string } = PropTypes;

IconStar.defaultProps = {
    className: null,
    rootClassName: null,
};

IconStar.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconStar;
