import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LocationIcon from '../../assets/icons/location-icon.svg';

const IconLocation = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={LocationIcon} alt="map pin icon" />;
};

const { string } = PropTypes;

IconLocation.defaultProps = {
    className: null,
    rootClassName: null,
};

IconLocation.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconLocation;
