import React from 'react';
import ClusterCard from '../../components/ClusterCard/ClusterCard';
import { FormattedMessage } from '../../util/reactIntl';
import IconDoor from '../../components/IconDoor/IconDoor';

import css from './ListingPage.module.css';

const SectionClusters = (props) => {
    const { clusters, addOns, intl, onManageDisableScrolling } = props;
    if (!clusters || !clusters.length) {
        return null;
    }

    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <IconDoor className={css.firstIcon} />
                <FormattedMessage id="ListingPage.activityTitle" />
            </h2>

            <div>
                {React.Children.toArray(
                    clusters.map((_act) => (
                        <ClusterCard
                            showInfoIcon
                            cluster={_act}
                            intl={intl}
                            addOns={addOns}
                            onManageDisableScrolling={onManageDisableScrolling}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default SectionClusters;
