import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AmenitiesIcon from '../../assets/icons/amenities-icon.svg';

const IconAmenities = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={AmenitiesIcon} alt="door icon" />;
};

const { string } = PropTypes;

IconAmenities.defaultProps = {
    className: null,
    rootClassName: null,
};

IconAmenities.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconAmenities;
