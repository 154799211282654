import React from 'react';
import AddOnCard from '../../components/AddOnCard/AddOnCard';
import { FormattedMessage } from '../../util/reactIntl';
import IconCancel from '../../components/IconCancel/IconCancel';

import css from './ListingPage.module.css';

const SectionCancelation = (props) => {
    const { addOns, intl } = props;

    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <IconCancel className={css.firstIcon} />
                <FormattedMessage id="ListingPage.cancelationTitle" />
            </h2>

            <div style={{ lineHeight: '1.5em' }}>
                <FormattedMessage id="ListingPage.cancelationContent" />
            </div>
        </div>
    );
};

export default SectionCancelation;
