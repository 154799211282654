import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../assets/icons/add-on-icon.svg';

const IconAddOn = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={Icon} alt="Add on icon" />;
};

const { string } = PropTypes;

IconAddOn.defaultProps = {
    className: null,
    rootClassName: null,
};

IconAddOn.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconAddOn;
